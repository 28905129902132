import { FC, PropsWithChildren, useMemo, useCallback } from "react";
import { Page as CorePage, PageProps as CorePageProps } from "am-tax-fe-core";
import { Link as ReactRouterLink, useLocation, useMatch, useNavigate } from "react-router-dom";
import { ApplicationRole, RoleNames, useHasAccess } from "../hooks";
import { Box, Flex, Heading, Icon, Link, MenuItem, Tooltip } from "@chakra-ui/react";
import { Engagement, Project, useEngagement, useProject, useRegionMatch } from "../api";
import { NotificationSettingsModal } from "../components/NotificationSettings";
import { EngagmentIcon, isEngagement, isProject, ProjectIcon } from "../util/engagementUtils.ts";
import { IconBell, IconUserCircle, IconSettings } from "@tabler/icons-react";
import { AccessStatus } from "../enums/AccessStatus.ts";
import { isNotificationSettingsModalOpenAtom } from "../atoms";
import { useAtom } from "jotai";

export type PageProps = PropsWithChildren<Omit<CorePageProps, "location" | "menuItems">>;

export const Page: FC<PageProps> = ({ children, ...pageProps }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { identity } = useHasAccess();
    const [isOpen, setIsOpen] = useAtom(isNotificationSettingsModalOpenAtom);
    const onOpen = useCallback(() => setIsOpen(true), [setIsOpen]);
    const onClose = useCallback(() => setIsOpen(false), [setIsOpen]);

    const isDeveloper = useMemo(() => (identity?.roles ?? []).map(r => RoleNames[r]).includes(RoleNames[ApplicationRole.developer]), [identity?.roles]);
    const menuItems = useMemo(() => {
        const items = [
            <MenuItem
                key={"myAccount"}
                icon={<IconUserCircle />}
                onClick={() => {
                    navigate("/myAccount");
                }}
            >
                My Account
            </MenuItem>,
            <MenuItem key={"notification"} icon={<IconBell />} onClick={onOpen}>
                Notification Settings
            </MenuItem>,
        ];
        if (isDeveloper) {
            items.push(
                <MenuItem key={"profile"} icon={<IconSettings />} onClick={() => navigate("/profile")}>
                    Profile Settings
                </MenuItem>,
            );
        }
        return items;
    }, [isDeveloper, navigate, onOpen]);

    const matchesEngagement = useMatch("/engagement/:engagementId/*");
    const engagementId = matchesEngagement?.params?.engagementId;

    const matchesProject = useMatch("/engagement/:engagementId/project/:projectId/*");
    const projectId = matchesProject?.params?.projectId;

    const engagementQuery = useEngagement(engagementId);
    const engagement = engagementQuery.data;
    const projectQuery = useProject(engagementId, projectId);
    const project = projectQuery.data;
    const projectOrEngagement: Project | Engagement | undefined = project ?? engagement;
    const engagementRegionMatch = useRegionMatch(engagement!, !!engagement);
    const projectRegionMatch = useRegionMatch(project!, !!project);

    return (
        <CorePage
            topNavFooter={
                projectOrEngagement && (
                    <Heading variant={"info"} fontSize={".9rem"} m={0}>
                        {isEngagement(projectOrEngagement) && (
                            <Tooltip label={`Region: ${engagementRegionMatch?.regionName || "default"}`} placement={"bottom-start"} openDelay={500}>
                                <Flex alignItems={"center"} gap={".25rem"}>
                                    <Icon as={EngagmentIcon} boxSize={"1.5rem"} />
                                    <Box>{projectOrEngagement.projectCode}</Box>
                                    <Box>-</Box>
                                    <Box as={"span"} fontWeight={600}>
                                        {projectOrEngagement.name}
                                    </Box>
                                </Flex>
                            </Tooltip>
                        )}
                        {isProject(projectOrEngagement) && (
                            <Flex gap={"1rem"} alignItems={"center"}>
                                <Tooltip label={`Region: ${engagementRegionMatch?.regionName || "default"}`} placement={"bottom-start"} openDelay={500}>
                                    <Flex alignItems={"center"} gap={".25rem"}>
                                        <Icon as={EngagmentIcon} boxSize={"1.5rem"} display={"inline-block"} />
                                        <Link
                                            as={ReactRouterLink}
                                            to={engagement?.accessStatus === AccessStatus.Accepted ? `/engagement/${engagementId}/dashboard` : undefined}
                                            _hover={{ cursor: engagement?.accessStatus === AccessStatus.Accepted ? "pointer" : "not-allowed" }}
                                        >
                                            {engagement?.projectCode}&nbsp;-&nbsp;
                                            <Box as={"span"} fontWeight={600}>
                                                {engagement?.name}
                                            </Box>
                                        </Link>
                                    </Flex>
                                </Tooltip>
                                <Box>/</Box>
                                <Tooltip label={`Region: ${projectRegionMatch?.regionName || "default"}`} placement={"bottom-start"} openDelay={500}>
                                    <Flex alignItems={"center"} gap={".25rem"}>
                                        <Icon as={ProjectIcon} boxSize={"1.5rem"} />
                                        <Box>{project?.projectCode}</Box>
                                        <Box>-</Box>
                                        <Box as={"span"} fontWeight={600}>
                                            {project?.name}
                                        </Box>
                                    </Flex>
                                </Tooltip>
                            </Flex>
                        )}
                    </Heading>
                )
            }
            location={location}
            topNavProps={{
                buildNumber: import.meta.env.VITE_BUILD_NUMBER,
            }}
            menuItems={menuItems}
            {...pageProps}
        >
            <NotificationSettingsModal isOpen={isOpen} onClose={onClose} />
            {children}
        </CorePage>
    );
};
