import { useEngagementAreaPermissions } from "../../hooks";
import { RequestArea } from "../../auth";
import {
    invalidateDocumentCacheForEngagementAndFolder,
    invalidateEngagementRequestQueries,
    Request,
    useDeleteEngagementRequest,
    useEngagement,
    useEngagementRequest,
    useEngagementUsers,
    useFolderStructure,
    useSaveEngagementRequest,
    useUploadDocumentToEngagementRequest,
} from "../../api";
import { Mutation } from "../../util/queryUtils.ts";
import { ProjectEngagement, RequestFormProps } from "../../components/RequestForm/RequestForm";
import { useMemo } from "react";
import { useQueryClient } from "@tanstack/react-query";

export function useRequestFormForEngagement(engagementId: string, requestId: string): Omit<RequestFormProps, "navigateBack"> {
    const areaPermissions = useEngagementAreaPermissions(RequestArea, engagementId);
    const projectEngagementQuery = useEngagement(engagementId);
    const deleteRequestQuery = useDeleteEngagementRequest();
    const requestQuery = useEngagementRequest(engagementId, requestId, !deleteRequestQuery.isPending && !deleteRequestQuery.isSuccess);
    const participantsQuery = useEngagementUsers(engagementId);
    const saveRequestQuery = useSaveEngagementRequest(true);
    const uploadFileToRequestQuery = useUploadDocumentToEngagementRequest(true);
    const folderStructureQuery = useFolderStructure(engagementId);

    const saveRequestMutation = useMemo(() => new Mutation(saveRequestQuery), [saveRequestQuery]);
    const deleteRequestMutation = useMemo(() => new Mutation(deleteRequestQuery), [deleteRequestQuery]);
    const uploadFileToRequestMutation = useMemo(() => new Mutation(uploadFileToRequestQuery), [uploadFileToRequestQuery]);

    const queryClient = useQueryClient();
    const onSaveComplete = (projectOrEngagement: ProjectEngagement, request: Request, filesUploaded?: boolean) => {
        const currFolderId = request.folderId || projectOrEngagement.clientDocumentsRootFolderId;
        invalidateEngagementRequestQueries(queryClient, request.engagementId, request.requestId);
        if (filesUploaded) {
            invalidateDocumentCacheForEngagementAndFolder(queryClient, request.engagementId, currFolderId);
        }
    };

    return {
        areaPermissions,
        projectEngagementQuery,
        requestQuery,
        participantsQuery,
        folderStructureQuery,
        saveRequestMutation,
        deleteRequestMutation,
        uploadFileToRequestMutation,
        onSaveComplete,
    };
}
