import { FunctionComponent, useRef, useState } from "react";

import { IconSend2, IconEye, IconEyeOff } from "@tabler/icons-react";
import { FormControl, IconButton, Flex, Box, Tooltip } from "@chakra-ui/react";

import { CommentRequest, User, CreateRequestCommentArgs, Participant } from "../../api";
import { useCurrentUser, useCreateRequestComment, useProjectUsers, useEngagementUsers } from "../../api/apiQueries";
import RichTextEditor, { RichTextEditorRef } from "./rich-text/RichTextEditor";

interface Props {
    requestId: string;
    conversationId: string;
    engagementId: string;
    projectId?: string;
}

const CreateCommentPanel: FunctionComponent<Props> = (props: Props) => {
    const richTextEditorRef = useRef<RichTextEditorRef>(null);
    const [content, setContent] = useState<{ html: string; text: string }>({ html: "", text: "" });
    const [isPublished, setIsPublished] = useState(true);

    const currentUserQuery = useCurrentUser();
    const currentUser = currentUserQuery.data as User;

    const projectUsersQuery = useProjectUsers(props.engagementId, props.projectId ?? "");
    const engagementUsersQuery = useEngagementUsers(props.engagementId);
    const participants: Participant[] | undefined = props.projectId ? projectUsersQuery.data : engagementUsersQuery.data;

    const createCommentQuery = useCreateRequestComment();
    const createComment = async (comment: CommentRequest) => {
        const args: CreateRequestCommentArgs = {
            ...props,
            ...comment,
        };
        await createCommentQuery.mutateAsync(args);
        setContent({ html: "", text: "" });
        richTextEditorRef.current?.reset();
    };

    const handleSubmit = () => {
        const comment: CommentRequest = {
            conversationId: props.conversationId,
            content: content.html,
            contentText: content.text,
            user: currentUser,
            isPublished,
        };
        createComment(comment);
    };

    const onContentChanged = (html: string, text: string) => {
        setContent({ html, text });
    };

    let placeholder = "Write a comment";
    if (participants && participants.length > 0) {
        placeholder += " (use @ to mention)";
    }

    return (
        <Box mt="1.25rem">
            <Flex align="flex-end" gap=".5rem">
                <FormControl>
                    <RichTextEditor
                        ref={richTextEditorRef}
                        mentions={participants}
                        placeholder={placeholder}
                        onContentChange={onContentChanged}
                        maxLength={500}
                    />
                </FormControl>

                <Flex direction="column" gap=".25rem" align="center">
                    {currentUser.isInternal && (
                        <Box textAlign="left">
                            <Tooltip label={isPublished ? "Hide comment from clients" : "Hidden from clients"}>
                                <IconButton
                                    aria-label="Hide comment from clients"
                                    icon={isPublished ? <IconEye size={18} /> : <IconEyeOff size={18} />}
                                    onClick={() => setIsPublished(!isPublished)}
                                    variant="naked"
                                    ml=".25rem"
                                    color="gray.500"
                                    mb=".5rem"
                                />
                            </Tooltip>
                        </Box>
                    )}
                    <Tooltip label="Send comment">
                        <IconButton
                            aria-label="Send comment"
                            icon={<IconSend2 />}
                            onClick={handleSubmit}
                            isLoading={currentUserQuery.isPending}
                            isDisabled={content.text.trim() === ""}
                        />
                    </Tooltip>
                </Flex>
            </Flex>
        </Box>
    );
};

export default CreateCommentPanel;
