import { MouseEvent } from "react";

import { useNavigate } from "react-router-dom";
import { ApplicationLayoutBoxShadow, NavGroup, Shimmer } from "am-tax-fe-core";
import {
    IconActivity,
    IconBell,
    IconCalendar,
    IconReport,
    IconDashboard,
    IconFileReport,
    IconFileLike,
    IconFiles,
    IconSettings,
    IconTruckLoading,
    IconUsers,
    IconPuzzle,
} from "@tabler/icons-react";
import { Box, Flex, Heading, Icon } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { Engagement, Project, useEngagement, useProject } from "../../api/index.ts";
import { useAppPermissions, useEngagementAppPermissions, useProjectAppPermissions } from "../../hooks/index.ts";
import { EngagmentIcon, isEngagement, ProjectIcon } from "../../util/engagementUtils.ts";
import {
    ActivityArea,
    AdminArea,
    CalendarArea,
    DeliverableArea,
    DocumentArea,
    EngagementNotificationSettingArea,
    EngagementSettingArea,
    ReportArea,
    RequestArea,
    UserArea,
} from "../../auth/index.ts";

interface EngagementOrProjectNavGroup {
    group: NavGroup;
    isLoading: boolean;
    error?: string | undefined;
}

export const useEngagementOrProjectGroup = (engagementId?: string, projectId?: string): EngagementOrProjectNavGroup => {
    const navigate = useNavigate();
    const { t } = useTranslation("LeftNav");

    const { data: engagement, isLoading: isEngagementLoading } = useEngagement(engagementId, !projectId);
    const { data: project, isLoading: isProjectLoading } = useProject(engagementId, projectId);
    const projectOrEngagement: Project | Engagement | undefined = project ?? engagement;
    const isLoading = isEngagementLoading || isProjectLoading;

    const engagementAppPermissions = useEngagementAppPermissions(engagementId, !projectId);
    const projectAppPermissions = useProjectAppPermissions(engagementId, projectId, !!projectId);
    const projectOrEngagementPermissions = projectId ? projectAppPermissions : engagementAppPermissions;
    const appPermissions = useAppPermissions();

    const injectedProjectPath = projectId ? `/project/${projectId}` : "";

    const groupItems = [
        {
            to: `/engagement/${engagementId}${injectedProjectPath}/dashboard`,
            leftIcon: <IconDashboard size="1rem" />,
            text: `${isEngagement(projectOrEngagement) ? t("engagement") : t("project")} ${t("dashboard")}`,
        },
        {
            show: projectOrEngagementPermissions.showArea(RequestArea),
            isDisabled: !projectOrEngagement?.clientDocumentsRootFolderId,
            to: `/engagement/${engagementId}${injectedProjectPath}/requests`,
            leftIcon: <IconFileReport size="1rem" />,
            text: t("requests"),
        },
        {
            show: projectOrEngagementPermissions.showArea(DocumentArea),
            isDisabled: !projectOrEngagement?.clientDocumentsRootFolderId,
            to: `/engagement/${engagementId}${injectedProjectPath}/documents`,
            onClick: (e: MouseEvent<HTMLAnchorElement>) => {
                e.preventDefault();
                navigate(`/engagement/${engagementId}${injectedProjectPath}/documents/${projectOrEngagement?.clientDocumentsRootFolderId}`);
            },
            leftIcon: <IconFiles size="1rem" />,
            text: `${isEngagement(projectOrEngagement) ? t("engagement") : t("project")}  ${t("document")}`,
        },
        {
            show: projectOrEngagementPermissions.showArea(DeliverableArea),
            isDisabled: !projectOrEngagement?.deliverablesRootFolderId,
            to: `/engagement/${engagementId}${injectedProjectPath}/deliverables`,
            onClick: (e: MouseEvent<HTMLAnchorElement>) => {
                e.preventDefault();
                navigate(`/engagement/${engagementId}${injectedProjectPath}/deliverables/${projectOrEngagement?.deliverablesRootFolderId}`);
            },
            leftIcon: <IconTruckLoading size="1rem" />,
            text: `${isEngagement(projectOrEngagement) ? t("invoices") : t("deliverables")}`,
        },
        {
            show: projectOrEngagementPermissions.showArea(DeliverableArea),
            isDisabled: !projectOrEngagement?.deliverablesRootFolderId,
            to: `/engagement/${engagementId}${injectedProjectPath}/deliverable-approvals`,
            leftIcon: <IconFileLike size="1rem" />,
            text: "Deliverable Approvals",
        },
        {
            show: projectOrEngagementPermissions.showArea(ReportArea),
            to: `/engagement/${engagementId}${injectedProjectPath}/reports`,
            leftIcon: <IconReport size="1rem" />,
            text: t("reports"),
        },
        {
            show: projectOrEngagementPermissions.showArea(CalendarArea),
            to: `/engagement/${engagementId}${injectedProjectPath}/calendar`,
            leftIcon: <IconCalendar size="1rem" />,
            text: t("calendar"),
        },
        {
            show: projectOrEngagementPermissions.showArea(ActivityArea),
            to: `/engagement/${engagementId}${injectedProjectPath}/activity`,
            leftIcon: <IconActivity size="1rem" />,
            text: t("activity"),
        },
        {
            show: projectOrEngagementPermissions.showArea(UserArea),
            to: `/engagement/${engagementId}${injectedProjectPath}/users`,
            leftIcon: <IconUsers size="1rem" />,
            text: t("manageUsers"),
        },
        {
            show: projectOrEngagementPermissions.showArea(EngagementSettingArea),
            to: `/engagement/${engagementId}${injectedProjectPath}/settings`,
            leftIcon: <IconSettings size="1rem" />,
            text: `${isEngagement(projectOrEngagement) ? t("engagement") : t("project")} ${t("settings")}`,
        },
        {
            show: projectOrEngagementPermissions.showArea(EngagementNotificationSettingArea),
            to: `/engagement/${engagementId}${injectedProjectPath}/notificationsettings`,
            leftIcon: <IconBell size="1rem" />,
            text: `${isEngagement(projectOrEngagement) ? t("engagement") : t("project")} ${t("notifications")}`,
        },
        {
            show: appPermissions.has(AdminArea.update),
            to: `/engagement/${engagementId}${injectedProjectPath}/applications`,
            leftIcon: <IconPuzzle size="1rem" />,
            text: "Applications Settings",
        },
    ];

    const group: NavGroup = {
        isGroup: true,
        groupColor: isEngagement(projectOrEngagement) ? "green.500" : "blue.400",
        itemDefaults: isEngagement(projectOrEngagement)
            ? { hoverColor: "greenAlpha.400", activeColor: "green.500" }
            : { hoverColor: "whiteAlpha.200", activeColor: "blue.400" },
        groupHeading: (
            <Box px={"1rem"} py={3} m={0} bg={"white"} boxShadow={ApplicationLayoutBoxShadow}>
                {projectOrEngagement ? (
                    <Flex flexDirection={"column"} alignItems={"center"} gap={"7px"}>
                        <Heading variant={"info"} size={"sm"} maxWidth={"100%"} textAlign={"center"}>
                            {projectOrEngagement?.clientName}
                        </Heading>

                        <Flex gap={"3px"} maxWidth={"100%"} alignItems={"center"}>
                            <Heading variant={"info"}>
                                {isEngagement(projectOrEngagement) ? (
                                    <Icon as={EngagmentIcon} boxSize={"1.5rem"} />
                                ) : (
                                    <Icon as={ProjectIcon} boxSize={"1.5rem"} />
                                )}
                            </Heading>
                            <Heading variant={"info"} size={"sm"}>
                                {projectOrEngagement?.projectCode}
                            </Heading>
                        </Flex>
                    </Flex>
                ) : (
                    <Shimmer />
                )}
            </Box>
        ),
        items: groupItems,
    };

    return { group, isLoading };
};
