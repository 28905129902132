import { FunctionComponent } from "react";
import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, Select, Spacer, VStack } from "@chakra-ui/react";
import { ClientSearchResponse, PostCustomEngagementArgs, useCreateCustomEngagement, useEngagementTypes, useRegions, useRegionStorageLookup } from "../../api";
import { useForm } from "react-hook-form";
import { MultiSelectControlled, Shimmer, useToast } from "am-tax-fe-core";
import { useEngagementAreaPermissions } from "../../hooks";
import { accessMatchesIntent, canCreateOrUpdate, EngagementArea, EngagementSettingArea } from "../../auth";
import { UnauthorizedPage } from "../UnauthorizedPage";
import { useNavigate } from "react-router-dom";
import { StorageTypes } from "../../enums/StorageType";

type FormValues = Partial<PostCustomEngagementArgs>;

interface EngagementSettingsFormProps {
    client: ClientSearchResponse | undefined;
    engagementName?: string;
    onCancel?: () => void;
}

export const ProvisionCustomEngagementForm: FunctionComponent<EngagementSettingsFormProps> = ({ client, engagementName, onCancel }) => {
    const toast = useToast();
    const navigate = useNavigate();
    const engagementTypesQuery = useEngagementTypes();
    const areaPermissions = useEngagementAreaPermissions(EngagementSettingArea, undefined);
    const regionsQuery = useRegions();
    const regionsQueryData = regionsQuery.data;
    const regionStorageLookupQuery = useRegionStorageLookup();
    const regionStorageLookup = regionStorageLookupQuery.data;
    const clientId = client?.clientId;

    // ---- Code to Load the Form ----
    const formValues: FormValues = {
        clientName: client?.clientName || "",
        engagementName: engagementName,
    };

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        watch,
    } = useForm<FormValues>({
        values: formValues,
    });
    const regionId = watch("regionId");
    const regionIdString = regionId ? String(regionId) : "";
    const storageOptions = regionStorageLookup ? regionStorageLookup.regionStorageOptions[regionIdString] : [];
    const createCustomEngagementQuery = useCreateCustomEngagement();
    const onSubmit = async (formData: Partial<PostCustomEngagementArgs>) => {
        const args: PostCustomEngagementArgs = {
            clientId: clientId,
            engagementTypeIds: formData.engagementTypeIds || [],
            clientName: formData.clientName!,
            engagementName: formData.engagementName!,
            allowedDomains: [],
        };
        if (formData.regionId && Number(formData.regionId)) {
            args.regionId = formData.regionId;
        }
        if (formData.storageType && StorageTypes.includes(Number(formData.storageType))) {
            args.storageType = formData.storageType;
        }
        const data = await createCustomEngagementQuery.mutateAsync(args);
        toast({
            title: "Saved",
            description: "Settings Saved.",
            status: "success",
            duration: 3000,
            isClosable: true,
        });
        navigate(`/engagement/${data.engagementId}/dashboard`);
    };

    if (!accessMatchesIntent(areaPermissions, EngagementArea.create, EngagementSettingArea.read, EngagementSettingArea.update, null, null)) {
        return <UnauthorizedPage />;
    }

    const clientNameLabel = client ? "Client Name" : "Custom Client Name";

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <fieldset disabled={createCustomEngagementQuery.isPending}>
                <VStack gap={"1rem"}>
                    <FormControl isInvalid={!!errors?.clientName}>
                        <FormLabel>{clientNameLabel}:</FormLabel>
                        <Input
                            readOnly={!!client}
                            {...register("clientName", {
                                required: `${clientNameLabel} is required.`,
                                pattern: {
                                    value: /^(?!.*[<>:"*/\\|])(?=[^\s.])(?!.*[\s.]$)[\w\s]*$/,
                                    message: `${clientNameLabel} cannot contain any of these special characters < > : " * / \\ | and cannot begin or end with a dot or a space`,
                                },
                            })}
                        />
                        <FormErrorMessage>{errors?.clientName?.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors?.engagementName}>
                        <FormLabel>Engagement Name:</FormLabel>
                        <Input
                            {...register("engagementName", {
                                required: "Enagement Name is required.",
                                pattern: {
                                    value: /^(?!.*[<>:"*/\\|])(?=[^\s.])(?!.*[\s.]$)[\w\s]*$/,
                                    message:
                                        'Enagement name cannot contain any of these special characters < > : " * / \\ | and cannot begin or end with a dot or a space`',
                                },
                            })}
                        />
                        <FormErrorMessage>{errors?.engagementName?.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors?.engagementTypeIds}>
                        <FormLabel>Engagement Types:</FormLabel>
                        <Shimmer isLoading={engagementTypesQuery.isLoading}>
                            <MultiSelectControlled
                                control={control}
                                name={"engagementTypeIds"}
                                placeholder={"Select Engagment Types..."}
                                rules={{ required: "Engagement Types are required." }}
                                options={engagementTypesQuery.data?.map(type => ({
                                    value: type.id,
                                    label: type.name,
                                }))}
                            />
                        </Shimmer>
                        <FormErrorMessage>{errors?.engagementTypeIds?.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors?.regionId}>
                        <Flex>
                            <FormLabel>Data Storage Region:</FormLabel>
                        </Flex>
                        <Shimmer isLoading={regionsQuery.isLoading}>
                            <Select isRequired={true} placeholder={"Select a Data Storage Region..."} {...register("regionId")}>
                                {regionsQueryData?.map(region => (
                                    <option key={region.id} value={region.id}>
                                        {region.regionName}
                                    </option>
                                ))}
                            </Select>
                        </Shimmer>
                        <FormErrorMessage>{errors?.regionId?.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors?.storageType}>
                        <Flex>
                            <FormLabel>Storage Option:</FormLabel>
                        </Flex>
                        <Shimmer isLoading={regionStorageLookupQuery.isLoading}>
                            <Select isRequired={true} placeholder={`Select a Storage Options..`} {...register("storageType")}>
                                {storageOptions?.map((option, index) => (
                                    <option key={index} value={option.storageType}>
                                        {option.storageName}
                                    </option>
                                ))}
                            </Select>
                        </Shimmer>
                    </FormControl>
                    <Flex justifyContent="flex-end" mt={"2rem"} width={"full"}>
                        <Spacer />
                        {canCreateOrUpdate(areaPermissions, EngagementArea.create, EngagementSettingArea.update, undefined) && (
                            <Button type="submit" variant={"primary"} mr={3} isLoading={createCustomEngagementQuery.isPending} loadingText={"Saving"}>
                                Save
                            </Button>
                        )}
                        {onCancel && (
                            <Button variant="ghost" onClick={onCancel}>
                                Cancel
                            </Button>
                        )}
                    </Flex>
                </VStack>
            </fieldset>
        </form>
    );
};
