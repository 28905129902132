//import { AMTaxLogoGlobalWhite, LoginPage, MsalLoginForm } from "am-tax-fe-core";
import { MsalLoginForm } from "am-tax-fe-core";
import BgImage from "../../assets/images/8C0862F6-1A5B-4941-8C67-874FCFAD2E05.jpg";
import AMTaxLogoGlobalWhite from "../../assets/images/am_tax_logo_global_white.svg?react";
import { LocalLoginPage } from "./LocalLoginPage";

export const UnauthenticatedLandingPage = () => {
    return (
        <LocalLoginPage bgImage={`url(${BgImage})`} appName={import.meta.env.VITE_APP_NAME} logoSvg={AMTaxLogoGlobalWhite}>
            <MsalLoginForm />
        </LocalLoginPage>
    );
};
