import * as React from "react";
const SvgAmTaxLogoGlobalWhite = (props) => /* @__PURE__ */ React.createElement("svg", { id: "svg227", width: 373.05334, height: 113.66667, viewBox: "0 0 373.05334 113.66667", xmlns: "http://www.w3.org/2000/svg", "xmlns:svg": "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("defs", { id: "defs231" }), /* @__PURE__ */ React.createElement("g", { id: "g235", transform: "matrix(1.3333333,0,0,-1.3333333,0,113.66667)" }, /* @__PURE__ */ React.createElement("path", { d: "m 111.407,0.222266 h -1.611 V 45.1656 h 1.611 V 0.222266", style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0.1
}, id: "path239" }), /* @__PURE__ */ React.createElement("path", { d: "M 90.6891,37.273 88.116,43.1813 85.4398,37.273 Z m -3.8141,7.8946 h 2.6234 L 95.557,31.7793 h -2.4797 l -1.755,4.0426 h -6.5368 l -1.83,-4.0426 h -2.3883 l 6.3078,13.3883", style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0.1
}, id: "path241" }), /* @__PURE__ */ React.createElement("path", { d: "m 88.2145,25.7699 c 0.4476,0.4184 0.6656,0.8731 0.6656,1.3813 0,0.3898 -0.1574,0.734 -0.4848,1.0164 -0.3234,0.2812 -0.7676,0.4285 -1.3223,0.4285 -0.841,0 -1.4253,-0.2258 -1.7464,-0.6727 -0.1719,-0.23 -0.252,-0.4785 -0.252,-0.7496 0,-0.3718 0.1254,-0.7304 0.3801,-1.0672 0.2582,-0.3406 0.6762,-0.7796 1.2766,-1.3281 0.7156,0.4082 1.2089,0.7395 1.4832,0.9914 m 0.1585,-7.5574 c 0.6051,0.3059 1.075,0.6566 1.3973,1.0363 l -3.7805,3.6813 c -1.0617,-0.5668 -1.7468,-1.0024 -2.075,-1.3063 -0.5003,-0.455 -0.7476,-1.0109 -0.7476,-1.6675 0,-0.7075 0.3207,-1.2571 0.9668,-1.636 0.6492,-0.3836 1.3402,-0.5691 2.0769,-0.5691 0.8364,0 1.5645,0.1535 2.1621,0.4613 m -4.939,7.4801 c -0.2375,0.4453 -0.3606,0.8808 -0.3606,1.2914 0,0.8633 0.3653,1.5828 1.0985,2.1582 0.7258,0.5707 1.6972,0.8562 2.9222,0.8562 1.1575,0 2.0649,-0.2629 2.7204,-0.7972 0.6507,-0.5328 0.9742,-1.1696 0.9742,-1.9051 0,-0.8609 -0.3383,-1.6121 -1.0074,-2.2617 -0.3958,-0.375 -1.0528,-0.8117 -1.9762,-1.3074 l 3.0476,-2.9059 c 0.1868,0.4879 0.3161,0.8519 0.3899,1.0883 0.0785,0.2433 0.1523,0.5777 0.2273,1.016 h 1.9961 c -0.1242,-0.8649 -0.3836,-1.684 -0.7722,-2.4734 -0.3836,-0.7809 -0.577,-1.1032 -0.577,-0.9446 l 2.925,-2.8461 h -2.6004 l -1.5703,1.4895 C 90.2535,17.623 89.6828,17.2348 89.1676,16.991 88.266,16.5594 87.2219,16.3465 86.05,16.3465 c -1.7355,0 -3,0.3715 -3.7895,1.1168 -0.782,0.7492 -1.1832,1.5879 -1.1832,2.5176 0,1.0171 0.384,1.8523 1.1524,2.5273 0.4699,0.4086 1.3445,0.9199 2.6269,1.5438 -0.7031,0.6429 -1.1781,1.1988 -1.4226,1.6406", style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0.1
}, id: "path243" }), /* @__PURE__ */ React.createElement("path", { d: "m 80.0555,13.8164 h 3.2172 l 4.7941,-11.3207 4.8039,11.3207 h 3.2016 V 0.429297 H 93.9359 V 8.33516 c 0,0.27304 0.0184,0.72304 0.0493,1.35898 0.0246,0.63086 0.0406,1.30976 0.0406,2.02926 L 89.175,0.429297 H 86.9242 L 82.098,11.7234 v -0.4082 c 0,-0.3324 0.0188,-0.8351 0.0434,-1.50778 0.0281,-0.67031 0.0469,-1.16133 0.0469,-1.47226 V 0.429297 H 80.0555 V 13.8164", style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0.1
}, id: "path245" }), /* @__PURE__ */ React.createElement("path", { d: "M 50.3355,85.2453 0,0.311328 H 11.6391 L 52.5793,72.352 V 0.311328 h 9.1262 V 85.2453 h -11.37", style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0.1
}, id: "path247" }), /* @__PURE__ */ React.createElement("path", { d: "M 49.0914,60.6781 39.9078,44.5355 V 0.311328 h 9.1836 V 60.6781", style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0.1
}, id: "path249" }), /* @__PURE__ */ React.createElement("path", { d: "M 64.7039,60.6781 73.8852,44.5355 V 0.311328 H 64.7039 V 60.6781", style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0.1
}, id: "path251" }), /* @__PURE__ */ React.createElement("path", { d: "m 126.381,44.9629 h 47.985 V 34.8871 H 157.867 V 0 H 142.88 v 34.8871 h -16.499 v 10.0758", style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0.1
}, id: "path253" }), /* @__PURE__ */ React.createElement("path", { d: "M 198.046,33.6273 H 197.92 L 191.685,16.625 h 12.595 z m -9.132,11.3356 H 207.24 L 226.573,0 h -16.059 l -2.707,7.30469 H 188.159 L 185.451,0 h -16.058 l 19.521,44.9629", style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0.1
}, id: "path255" }), /* @__PURE__ */ React.createElement("path", { d: "m 243.01,23.3629 -17.444,21.6 h 17.822 l 8.249,-12.0906 8.25,12.0906 h 17.821 L 260.264,23.3629 279.786,0 H 261.209 L 251.637,13.602 242.065,0 h -18.577 l 19.522,23.3629", style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0.1
}, id: "path257" })));
export default SvgAmTaxLogoGlobalWhite;
