import { atom } from "jotai";
import { Project, ProjectSearchArgs } from "../api";
import { Option } from "am-tax-fe-core";

export const clientIdAtom = atom<string | undefined>(undefined);
export const selectedIndexAtom = atom<number | undefined>(undefined);
export const isNotificationSettingsModalOpenAtom = atom<boolean>(false);

export type ProjectSearchForm = ProjectSearchArgs & {
    leadMd?: Option<string>;
    leadDirector?: Option<string>;
};
export const projectSearchForm = atom<ProjectSearchForm>({});

export const projectsAtom = atom<Project[]>([]);
export const showEngagementShadeAtom = atom<boolean>(false);
