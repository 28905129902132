import {
    Modal,
    Checkbox,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    FormLabel,
    Spinner,
    Box,
    ModalOverlay,
    ListItem,
    List,
    HStack,
    Link,
    ModalFooter,
    Text,
} from "@chakra-ui/react";
import {
    NotificationGroupOptionResponse,
    GlobalNotificationSettingsRequest,
    useNotificationOptions,
    useGlobalNotificationSettings,
    useSaveGlobalNotificationSettings,
} from "../../api";
import { FC, useState, Fragment } from "react";
import useStateRef from "react-usestateref";

type NotificationSettingsModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

export type NotificationGroup = {
    name: string;
    options: {
        [key: string]: string | undefined;
    };
};

export const NotificationSettingsModal: FC<NotificationSettingsModalProps> = ({ isOpen, onClose }) => {
    const notificationOptionsQuery = useNotificationOptions();
    const notificationGroupOptions = notificationOptionsQuery.data;

    const globalNotificationSettingsQuery = useGlobalNotificationSettings();
    const globalNotificationSettings = globalNotificationSettingsQuery.data;

    const saveGlobalNotificationSettingsQuery = useSaveGlobalNotificationSettings();
    const [spinnerState, setSpinnerState, spinnerStateRef] = useStateRef<Record<number, boolean>>({});

    const [updateMessage, setUpdateMessage] = useState("");
    const updateSpinners = (notificationIds: Array<number>, isSpinning: boolean) => {
        const temp = { ...spinnerStateRef.current };
        notificationIds.forEach(id => {
            temp[id] = isSpinning;
        });
        setSpinnerState(temp);
    };

    const isSettingEnabled = (id: number): boolean => {
        return globalNotificationSettings?.notificationIds?.includes(id) || false;
    };
    const saveAllInGroup = async (group: NotificationGroupOptionResponse, enabled: boolean) => {
        const arr: Array<GlobalNotificationSettingsRequest> = Object.keys(group.options).map(id => ({
            notificationId: parseInt(id),
            enabled,
        }));
        await saveNotificationSettings(arr);
    };

    const saveNotificationSettings = async (settingsToUpdate: Array<GlobalNotificationSettingsRequest>) => {
        const settingsIds = settingsToUpdate.map(setting => setting.notificationId);
        try {
            setUpdateMessage("");
            updateSpinners(settingsIds, true);
            await saveGlobalNotificationSettingsQuery.mutateAsync(settingsToUpdate);
            const time = new Date().toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true });
            setUpdateMessage("Settings updated at " + time);
        } catch (e) {
            setUpdateMessage("Failed to update settings");
        } finally {
            updateSpinners(settingsIds, false);
        }
    };

    const filterOutReminders = (notificationGroup: NotificationGroup): boolean => {
        return notificationGroup.name != "Reminders";
    };

    return (
        <Modal size={"xl"} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Notification Settings</ModalHeader>
                <ModalCloseButton />
                <ModalBody p={"0 2rem 2rem 2rem"}>
                    <Text mb={"1em"}>Settings apply to all engagements</Text>
                    {notificationGroupOptions?.filter(filterOutReminders).map((notificationGroup, i) => (
                        <Fragment key={i}>
                            <HStack spacing={"1rem"} mb={"0.5rem"}>
                                <FormLabel m={0}>{notificationGroup.name}</FormLabel>
                                <Box fontSize={"0.8rem"}>
                                    <Link
                                        color={"blackAlpha.600"}
                                        fontSize={"0.8rem"}
                                        onClick={() => {
                                            saveAllInGroup(notificationGroup, true);
                                        }}
                                    >
                                        all
                                    </Link>{" "}
                                    |{" "}
                                    <Link
                                        color={"blackAlpha.600"}
                                        fontSize={"0.8rem"}
                                        onClick={() => {
                                            saveAllInGroup(notificationGroup, false);
                                        }}
                                    >
                                        none
                                    </Link>
                                </Box>
                            </HStack>
                            <List ml={"1rem"} mb={"1rem"}>
                                {Object.keys(notificationGroup.options).map(idString => {
                                    const id = parseInt(idString);
                                    return (
                                        <ListItem position={"relative"} key={id}>
                                            <Checkbox
                                                key={id}
                                                isChecked={isSettingEnabled(id)}
                                                onChange={e => saveNotificationSettings([{ notificationId: id, enabled: e.target.checked }])}
                                            >
                                                {notificationGroup.options[id]}
                                            </Checkbox>
                                            {spinnerState[id] && (
                                                <Box position={"absolute"} left={"1px"} top={0}>
                                                    <Spinner size={"xs"} color="blue.500" />
                                                </Box>
                                            )}
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </Fragment>
                    ))}
                </ModalBody>
                <ModalFooter>
                    <Text variant={"info"}>{updateMessage}</Text>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
